import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { RegisterForm } from '../../auth';
import { SiteUrls } from '../../../utils/constants';
import { Spinner } from '../../common';

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  height: 100vh;
  position: relative;
`;

type Props = {
  className?: string;
};

const AccountLoginForm: React.FC<Props> = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container className={className}>
      <RegisterForm
        submitText="Sign up"
        onClickLogin={() => navigate(SiteUrls.AccountLogin)}
        onClickForgetPassword={() => navigate(SiteUrls.AccountForgetPassword)}
        onBeforeRequest={() => setIsLoading(true)}
        onAfterRequest={result => {
          setIsLoading(false);
          if (!result) return;
          toast.success(
            'Successfully registered! You will be redirected shortly'
          );
          setTimeout(() => {
            navigate(SiteUrls.AccountDashboard);
          }, 3000);
        }}
      />
      <Spinner isLoading={isLoading} />
    </Container>
  );
};

export default AccountLoginForm;
