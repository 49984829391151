import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { ForgetPasswordForm, LoginForm } from '../../auth';
import { SiteUrls } from '../../../utils/constants';
import { Spinner } from '../../common';
import { useAuthState } from '../../../hooks/auth-context';

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  height: 100vh;
  position: relative;
`;

type Props = {
  className?: string;
};

const AccountForgetPasswordForm: React.FC<Props> = ({ className }) => {
  const { accessToken } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!accessToken) return;
    navigate(SiteUrls.AccountDashboard);
  }, [accessToken]);

  return (
    <Container className={className}>
      <ForgetPasswordForm
        onClickRegister={() => navigate(SiteUrls.AccountRegister)}
        onClickLogin={() => navigate(SiteUrls.AccountLogin)}
        onBeforeRequest={() => setIsLoading(true)}
        onAfterRequest={() => setIsLoading(false)}
      />
      <Spinner isLoading={isLoading} />
    </Container>
  );
};

export default AccountForgetPasswordForm;
