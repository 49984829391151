import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';

import { LoginForm } from '../../auth';
import { SiteUrls } from '../../../utils/constants';
import { Spinner } from '../../common';
import { useAuthDispatch, useAuthState } from '../../../hooks/auth-context';

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  height: 100vh;
  position: relative;
`;

type Props = {
  className?: string;
};

const AccountLoginForm: React.FC<Props> = ({ className }) => {
  const { accessToken } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!accessToken || !firebase.auth().currentUser) {
      dispatch({ type: 'SIGN_OUT' });
    } else {
      navigate(SiteUrls.AccountDashboard);
    }
  }, [accessToken]);

  return (
    <Container className={className}>
      <LoginForm
        submitText="Sign in"
        registerMode="signup"
        onClickRegister={() => navigate(SiteUrls.AccountRegister)}
        onClickForgetPassword={() => navigate(SiteUrls.AccountForgetPassword)}
        onBeforeRequest={() => setIsLoading(true)}
        onAfterRequest={() => setIsLoading(false)}
      />
      <Spinner isLoading={isLoading} />
    </Container>
  );
};

export default AccountLoginForm;
