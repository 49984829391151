import React from 'react';

import { AccountHero, AccountLayout } from '../../components/account/common';
import { AccountLoginForm } from '../../components/account/auth';

const AccountLoginPage = (): JSX.Element => (
  <AccountLayout
    allowAnonymous
    hero={<AccountHero disableBackground title="Login" />}
  >
    <AccountLoginForm />
  </AccountLayout>
);

export default AccountLoginPage;
